var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vue-perfect-scrollbar', {
    staticClass: "sidebar-menu-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "sidebar-task-handler",
      "sidebar-class": "sidebar-lg",
      "visible": _vm.isTaskHandlerSidebarActive,
      "bg-variant": "white",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    on: {
      "change": function change(val) {
        return _vm.$emit('update:is-task-handler-sidebar-active', val);
      },
      "hidden": _vm.clearForm
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        }, [_c('div', {
          staticClass: "font-weight-bolder text-black"
        }, [_vm._v(" Cài đặt phí & Bộ lọc ")]), _c('div', [_c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1)]), _vm._t("fee"), _vm._t("filter")];
      }
    }, {
      key: "footer",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "d-flex-center px-3 py-2"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Quay lại ")])], 1)];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }