<template>
  <div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="sidebar-menu-list scroll-area"
    >
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        :visible="isTaskHandlerSidebarActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
        @hidden="clearForm"
      >
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <div class="font-weight-bolder text-black">
              Cài đặt phí & Bộ lọc
            </div>

            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>

          <slot name="fee" />
          <slot name="filter" />
        <!-- ANCHOR: Body - Form -->
        <!-- <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Assignee"
            label-for="assignee"
          >
            hi filter
          </b-form-group>

          Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ taskLocal.id ? 'Update' : 'Add ' }}
            </b-button>

          </div>
        </b-form> -->
        </template>

        <template #footer="{ hide }">
          <div class="d-flex-center px-3 py-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="hide"
            >
              Quay lại
            </b-button>
          </div>
        </template>
      </b-sidebar>
    </vue-perfect-scrollbar>

  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line no-unused-vars
import { toRefs } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // const {
    //   resetTaskLocal,

    //   // UI
    //   assigneeOptions,
    //   onSubmit,
    //   tagOptions,
    //   resolveAvatarVariant,
    // } = useTaskHandler(toRefs(props), emit)

    const taskLocal = {}

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const onSubmit = () => {
    // eslint-disable-next-line no-undef
      const taskData = JSON.parse(JSON.stringify(taskLocal))

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (props.task.value.id) emit('update-task', taskData.value)
      else emit('add-task', taskData.value)

      // Close sidebar
      emit('update:is-task-handler-sidebar-active', false)
    }

    function resetForm() {
      //
    }
    function clearForm() {
      //
    }

    return {
      onSubmit,
      resetForm,
      clearForm,
      perfectScrollbarSettings,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  </style>
